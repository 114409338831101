<template>
 <SuccessBanner
  :key="key"
  :msg="successMsg"
  :active="activeBanner"
  @closeBanner="activeBanner = false"
  :isSuccess="isSuccess" />

 <ExpertStatisticsTemplate
  :periodSelector="periodSelector"
  @timeSelector="$emit('timeSelector', $event)"
  :elements="extensions"
  :clearTimeSelector="clearTimeSelector"
  @selector="
   $emit('update:selector', $event),
    $emit('setSelector', $event),
    $store.dispatch('pbxPeriodSelected', $event)
  "
  @setPeriod="(...args) => $emit('setPeriod', ...args)"
  :startDate="startDate"
  :endDate="endDate"
  @activeBanner="(activeBanner = true), (key = !key)"
  @successMsg="successMsg = $event"
  @success="isSuccess = $event"
  :urlType="urlType"
  @setLoading="$emit('setLoading', $event)">
  <template v-slot:selector>
   <PbxDashboardSelector
    @selector="
     $emit('update:selector', $event),
      $emit('setSelector', $event),
      $store.dispatch('pbxPeriodSelected', $event)
    "
    @button-selector="(...args) => $emit('button-selector', ...args)"
    @action="$emit('action', $event)"
    @concatenate="(concatenateData = $event), $emit('concatenate', $event)"
    @all-selected="$emit('all-selected', $event)"
    :formatDateToTime="this.formatDateToTime()"
    :concatenateData="concatenateData"
    :elements="extensions"
    :elementsLength="extensions.length"
    :urlType="urlType"
    :buttonSelected="this.buttonSelected"
    @elementToRemove="elementToRemove = $event"
    @timeSelector="$emit('timeSelector', $event)"
    :clearTimeSelector="clearTimeSelector"
    :queryElement="queryElement"
    @loading="isLoading = $event"
    @selectedDurationMenuItem="selectedDurationMenuItem = $event" />
  </template>
  <template v-slot:content>
   <PbxHeader
    :selectHostname="selectHostname"
    :buttonSelected="buttonSelected"
    :parseDateRange="parseDateRange"
    :parseTimeRange="parseTimeRange"
    :periodSelector="periodSelector"
    :timeSelected="timeSelected"
    :urlType="urlType"
    :pbxMenuType="pbxMenuType"
    :elementToRemove="elementToRemove"
    :elements="extensions"
    @selector="$emit('update:selector', $event)"
    @button-selector="(...args) => $emit('button-selector', ...args)"
    @action="$emit('action', $event)"
    @concatenate="concatenateData = $event"
    @all-selected="$emit('all-selected', $event)"
    @select-hostname="this.selectHostname = $event"
    @select-hostContext="$emit('select-hostContext')"
    :formatDateToTime="this.formatDateToTime()"
    :concatenateData="concatenateData"
    :elementsLength="extensions.length"
    @elementToRemove="elementToRemove = $event"
    @activeBanner="(activeBanner = true), (key = !key)"
    @successMsg="successMsg = $event"
    @success="isSuccess = $event"
    :selections="extensions" />
   <div class="flex gap-2 justify-between py-4">
    <div
     class="lg:flex items-center text-center gap-1 w-full lg:w-2/3 border-b border-teal-500">
     <!-- Vue par heure -->
     <button
      @click="selectedAnsweredType = 'hour'"
      :class="[
       selectedAnsweredType === 'hour'
        ? ' border-teal-600 shadow-lg'
        : ' border-transparent',
       'relative flex-1 gap-1 shadow-md hover:shadow-lg text-center cursor-pointer rounded-t-lg transition-colors duration-200 ease-in-out focus:outline-none px-1 py-2 border-b-2 bg-white  text-gray-700',
      ]">
      <span class="text-sm font-bold">{{ $t("Vue consolidée par heure") }}</span>
     </button>

     <!-- Vue par week day -->
     <button
      @click="selectedAnsweredType = 'week'"
      :class="[
       selectedAnsweredType === 'week'
        ? ' border-teal-600 shadow-lg'
        : ' border-transparent',
       'relative flex-1 gap-1 shadow-md hover:shadow-lg text-center cursor-pointer rounded-t-lg transition-colors duration-200 ease-in-out focus:outline-none px-1 py-2 border-b-2 bg-white  text-gray-700',
      ]">
      <span class="text-sm font-bold">{{ $t("Vue consolidée par jour") }}</span>
     </button>

     <!-- Vue par semaine -->
     <button
      @click="selectedAnsweredType = 'day'"
      :class="[
       selectedAnsweredType === 'day'
        ? ' border-teal-600 shadow-lg'
        : ' border-transparent',
       'relative flex-1 gap-1 shadow-md hover:shadow-lg text-center cursor-pointer rounded-t-lg transition-colors duration-200 ease-in-out focus:outline-none px-1 py-2 border-b-2 bg-white  text-gray-700',
      ]">
      <span class="text-sm font-bold">{{ $t("Evolution par jour") }}</span>
     </button>

     <!-- Vue par week -->
     <button
      @click="selectedAnsweredType = 'weekNumber'"
      :class="[
       selectedAnsweredType === 'weekNumber'
        ? ' border-teal-600 shadow-lg'
        : ' border-transparent',
       'relative flex-1 gap-1 shadow-md hover:shadow-lg text-center cursor-pointer rounded-t-lg transition-colors duration-200 ease-in-out focus:outline-none px-1 py-2 border-b-2 bg-white  text-gray-700',
      ]">
      <span class="text-sm font-bold">{{ $t("Evolution par semaine") }}</span>
     </button>

     <!-- Vue par mois -->
     <button
      @click="selectedAnsweredType = 'month'"
      :class="[
       selectedAnsweredType === 'month'
        ? ' border-teal-600 shadow-lg'
        : ' border-transparent',
       'relative flex-1 gap-1 shadow-md hover:shadow-lg text-center cursor-pointer rounded-t-lg transition-colors duration-200 ease-in-out focus:outline-none px-1 py-2 border-b-2 bg-white  text-gray-700',
      ]">
      <span class="text-sm font-bold">{{ $t("Evolution par mois") }}</span>
     </button>
    </div>

    <SwitchGroup
     v-if="$route.name === 'ExpertStatistics'"
     as="div"
     class="flex items-center text-center gap-2">
     <Switch
      v-model="origins_calls_stats_default"
      :class="[
       !origins_calls_stats_default ? 'bg-teal-600' : 'bg-teal-600',
       'relative flex gap-1 text-center h-7 w-52 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
      ]">
      <span
       v-if="!origins_calls_stats_default"
       class="text-sm font-bold text-white px-3 py-0.5"
       >{{ $t("Famille d'origine") }}</span
      >
      <!-- <span class="text-sm text-gray-500">20s</span> -->
      <span
       aria-hidden="true"
       :class="[
        origins_calls_stats_default ? 'translate-x-0' : 'translate-x-1 w-16',
        'pointer-events-none  h-6 text-center items-center px-2 py-0.5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
       ]">
       <SwitchLabel as="span" class="flex cursor-pointer">
        <span
         v-if="!origins_calls_stats_default"
         class="text-sm font-bold text-gray-700"
         >{{ $t("Top 10") }}</span
        >
        <span v-else class="text-sm font-bold text-gray-700">{{
         $t("Famille d'origine")
        }}</span>
        <!-- <span class="text-sm text-gray-500">20s</span> -->
       </SwitchLabel>
      </span>
      <span
       v-if="origins_calls_stats_default"
       class="text-sm font-bold text-white capitalize px-2 py-0.5"
       >{{ $t("top 10") }}</span
      >
      <!-- <span class="text-sm text-gray-500">20s</span> -->
     </Switch>
    </SwitchGroup>
    <SwitchGroup
     v-if="
      $route.name === 'ExpertStatistics' ||
      $route.name === 'ExpertStatisticsAnsweredUnanswered'
     "
     as="div"
     class="flex items-center text-center gap-2">
     <span class="text-sm font-bold text-gray-600">{{
      urlType === "queue"
       ? $t("Afficher les files sans appel")
       : $t("Afficher les utilisateurs sans appel")
     }}</span>
     <Switch
      v-model="origins_calls_stats_filter"
      :class="[
       origins_calls_stats_filter ? 'bg-gray-400' : 'bg-teal-600',
       'relative flex gap-1 text-center h-7 w-12 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none',
      ]">
      <!-- <span class="text-sm text-gray-500">20s</span> -->
      <span
       aria-hidden="true"
       :class="[
        origins_calls_stats_filter ? 'translate-x-0' : 'translate-x-5 ',
        'pointer-events-none  h-6 text-center items-center px-2 py-0.5 w-6 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out',
       ]">
       <SwitchLabel as="span" class="flex cursor-pointer">
        <!-- <span
         v-if="origins_calls_stats_filter"
         class="text-sm font-bold text-gray-700"
         >{{ $t("Pas de filtre") }}</span
        >
        <span v-else class="text-sm font-bold text-gray-700">{{
         $t("Afficher tout")
        }}</span> -->
        <!-- <span class="text-sm text-gray-500">20s</span> -->
       </SwitchLabel>
      </span>
      <!-- <span
       v-if="!origins_calls_stats_filter"
       class="text-sm font-bold text-white px-2 py-0.5"
       >{{ $t("Pas de filtre") }}</span
      > -->
      <!-- <span class="text-sm text-gray-500">20s</span> -->
     </Switch>
    </SwitchGroup>
   </div>
   <div class="relative items-stretch">
    <main class="">
     <div class="px-2">
      <div
       v-if="showInfoText && $route.name === 'ExpertStatisticsCallDurations'"
       class="relative bg-blue-200 rounded-lg px-2 py-4 my-2">
       <div class="absolute right-2 top-2">
        <XIcon
         @click="showInfoText = false"
         class="h-4 w-4 text-blue-500 hover:text-blue-600 cursor-pointer"
         aria-hidden="true" />
       </div>
       <div class="flex gap-2 items-start text-left">
        <div>
         <InformationCircleIcon
          class="h-6 w-6 text-blue-400"
          aria-hidden="true" />
        </div>
        <div class="space-y-1">
         <p class="text-blue-700 text-sm font-medium">
          {{ $t("expertStatsDurationsInfoText") }}
         </p>
         <p class="text-blue-700 text-sm font-medium">
          {{ $t("expertStatsDurationsInfoSubTextOne") }}
         </p>
         <p class="text-blue-700 text-sm font-medium">
          {{ $t("expertStatsDurationsInfoSubTextTwo") }}
         </p>
        </div>
       </div>
      </div>

      <!-- <div
      v-if="
       outgoingCallsData && outgoingCallsData.series && outgoingCallsData.series[0].data.length > 0
      "
     >
      <BasicColumnDashboard
       :key="outgoingCallsData['labels']"
       @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
       :seriesForColumn="outgoingCallsData['series']"
       :categoriesForColumn="outgoingCallsData['labels']"
       :colors="colors"
       @loading="isLoading = $event"
       :showWaitingDuration="showWaitingDuration"
       :title="$t('percentageWaitingTime')"
       @waitingTimeRange="$emit('waitingTimeRange', $event)"
       :waitingTime="waitingTimeRange"
       :selectedDurationMenuItem="selectedDurationMenuItem"
      />
     </div> -->
      <div
       v-if="
        elementLevelDataRange &&
        Object.keys(elementLevelDataRange).length &&
        elementLevelDataRange['labels'] &&
        selectedDurationMenuItem === 'percentageWaitingTime'
       "
       class="space-y-6">
       <BasicColumnDashboard
        :answered_calls_stats_day="answered_calls_stats_day"
        :selectedAnsweredType="selectedAnsweredType"
        :key="elementLevelDataRange['labels']"
        @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
        :seriesForColumn="elementLevelDataRange['series']"
        :categoriesForColumn="elementLevelDataRange['labels']"
        :colors="colors"
        @loading="isLoading = $event"
        :showWaitingDuration="showWaitingDuration"
        :title="$t('percentageWaitingTime')"
        @waitingTimeRange="$emit('waitingTimeRange', $event)"
        :waitingTime="waitingTimeRange"
        :selectedDurationMenuItem="selectedDurationMenuItem" />
      </div>
      <div
       v-if="
        elementLevelDataRange &&
        Object.keys(elementLevelDataRange).length &&
        elementLevelDataRange['labels'] &&
        selectedDurationMenuItem === 'percentageWaitingTime' &&
        extensions.length > 0
       "
       class="space-y-6">
       <div v-if="dataElementsDuration && dataElementsDuration.length">
        <div v-for="data in dataElementsDuration" :key="data">
         <BasicColumnDashboard
          :answered_calls_stats_day="answered_calls_stats_day"
          :selectedAnsweredType="selectedAnsweredType"
          :key="data.data.labels"
          :name="data.name"
          @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
          :seriesForColumn="data.data.series"
          :categoriesForColumn="data.data.labels"
          :colors="colors"
          @loading="isLoading = $event"
          :showWaitingDuration="showWaitingDuration"
          :title="$t('avgWaitingTime') + ` - ${data.name}`"
          :totalDuration="data.total"
          @waitingTimeRange="$emit('waitingTimeRange', $event)"
          :waitingTime="waitingTimeRange"
          selectedDurationMenuItem="durationWaitingTime" />
        </div>
       </div>
      </div>
      <div
       v-if="
        elementLevelData &&
        Object.keys(elementLevelData).length &&
        elementLevelData['labels'] &&
        selectedDurationMenuItem === 'durationWaitingTime'
       "
       class="space-y-6">
       <BasicColumnDashboard
        :answered_calls_stats_day="answered_calls_stats_day"
        :selectedAnsweredType="selectedAnsweredType"
        :key="elementLevelData['labels']"
        @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
        :seriesForColumn="elementLevelData['series']"
        :categoriesForColumn="elementLevelData['labels']"
        :colors="colors"
        @loading="isLoading = $event"
        :showWaitingDuration="showWaitingDuration"
        :title="$t('durationWaitingTime')"
        @waitingTimeRange="$emit('waitingTimeRange', $event)"
        :waitingTime="waitingTimeRange"
        :selectedDurationMenuItem="selectedDurationMenuItem" />
      </div>
      <div
       v-if="
        dataElementsDuration &&
        dataElementsDuration.length &&
        selectedDurationMenuItem === 'durationWaitingTime'
       "
       class="space-y-6">
       <div v-for="data in dataElementsDuration" :key="data">
        <BasicColumnDashboard
         :answered_calls_stats_day="answered_calls_stats_day"
         :selectedAnsweredType="selectedAnsweredType"
         :key="data.data.labels"
         :name="data.name"
         @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
         :seriesForColumn="data.data.series"
         :categoriesForColumn="data.data.labels"
         :colors="colors"
         @loading="isLoading = $event"
         :showWaitingDuration="showWaitingDuration"
         :title="$t('avgCallDuration') + ` - ${data.name}`"
         :totalDuration="data.total"
         @waitingTimeRange="$emit('waitingTimeRange', $event)"
         :waitingTime="waitingTimeRange"
         :selectedDurationMenuItem="selectedDurationMenuItem" />
       </div>
      </div>
      <!-- <div
      v-if="dataPbx && dataPbx.data && dataPbx.data.series.length && dataPbx.data.labels.length"
     >
      <BasicColumnDashboard
      :answered_calls_stats_day="answered_calls_stats_day"
      :selectedAnsweredType="selectedAnsweredType"
       :key="dataPbx.data.labels"
       :seriesForColumn="dataPbx.data.series"
       @toggleWaitingDuration="showWaitingDuration = !showWaitingDuration"
       :categoriesForColumn="dataPbx.data.labels"
       :colors="colors"
       @loading="isLoading = $event"
       :showWaitingDuration="showWaitingDuration"
       :title="$t('avgCallDuration') + ` - ${dataPbx.name}`"
       :totalDuration="dataPbx.total"
      />
     </div> -->
      <div
       v-if="
        concatenateData &&
        dataColumnChartQuarterHourlyCons &&
        dataColumnChartQuarterHourlyCons[0]
       "
       class="space-y-6">
       <div v-if="!showChartHourly">
        <BasicColumnDashboard
         :answered_calls_stats_day="answered_calls_stats_day"
         :selectedAnsweredType="selectedAnsweredType"
         :key="pbxElements"
         @toggleChartHourly="showChartHourly = !showChartHourly"
         v-if="
          dataColumnChartQuarterHourlyCons[0].data &&
          dataColumnChartQuarterHourlyCons[0].labels
         "
         :seriesForColumn="dataColumnChartQuarterHourlyCons[0].data"
         :categoriesForColumn="dataColumnChartQuarterHourlyCons[0].labels"
         :colors="colors"
         :showChartHourly="showChartHourly"
         @loading="isLoading = $event" />
       </div>
       <div
        v-if="
         showChartHourly &&
         dataColumnChartHourlyCons &&
         dataColumnChartHourlyCons[0]
        "
        class="space-y-6">
        <BasicColumnDashboard
         :answered_calls_stats_day="answered_calls_stats_day"
         :selectedAnsweredType="selectedAnsweredType"
         :key="pbxElements"
         @toggleChartHourly="showChartHourly = !showChartHourly"
         v-if="
          dataColumnChartHourlyCons[0].data &&
          dataColumnChartHourlyCons[0].labels
         "
         :seriesForColumn="dataColumnChartHourlyCons[0].data"
         :categoriesForColumn="dataColumnChartHourlyCons[0].labels"
         :colors="colors"
         :showChartHourly="showChartHourly"
         @loading="isLoading = $event" />
       </div>
      </div>

      <div v-else>
       <div
        v-if="dataColumnChartQuarterHourly && !showChartHourly"
        class="space-y-6">
        <div v-for="data in dataColumnChartQuarterHourly" :key="data">
         <BasicColumnDashboard
          :answered_calls_stats_day="answered_calls_stats_day"
          :selectedAnsweredType="selectedAnsweredType"
          :key="pbxElements"
          @toggleChartHourly="showChartHourly = !showChartHourly"
          :seriesForColumn="data.data"
          :categoriesForColumn="data.labels"
          :colors="colors"
          :title="data.name"
          :showChartHourly="showChartHourly"
          @loading="isLoading = $event"
          :fullData="data" />
        </div>
       </div>
       <div v-if="dataColumnChartHourly && showChartHourly">
        <div v-for="data in dataColumnChartHourly" :key="data">
         <BasicColumnDashboard
          :answered_calls_stats_day="answered_calls_stats_day"
          :selectedAnsweredType="selectedAnsweredType"
          :key="pbxElements"
          @toggleChartHourly="showChartHourly = !showChartHourly"
          :seriesForColumn="data.data"
          :categoriesForColumn="data.labels"
          :colors="colors"
          :showChartHourly="showChartHourly"
          :title="data.name"
          @loading="isLoading = $event"
          :fullData="data" />
        </div>
       </div>
      </div>
      <!-- <StatsHeading :stats="this.stats" /> -->
      <div
       v-if="
        concatenateData && dataSeriesConcatenated && dataSeriesConcatenated[0]
       "
       class="pt-5">
       <div class="lg:grid gap-2 grid-cols-2">
        <DonutsDashboard
         :key="dataSeriesConcatenated[0]"
         v-show="dataSeriesConcatenated[0].data"
         :data="dataSeriesConcatenated[0].data"
         :title="$t('dashboards.stackedColumnCategories.incomingCount')"
         :colors="this.colors"
         @timeSelector="$emit('timeSelector', $event)"
         :concatenateData="concatenateData"
         @loading="isLoading = $event" />
        <!-- <StackedBarChart
            :dataSeries="dataSeriesCurrentMonth"
            :chartCategories="dataLabelsCurrentMonth"
          /> -->
       </div>
      </div>
      <div v-else class="pt-5">
       <div
        :class="[
         showDetail
          ? 'lg:grid-cols-2 xl:grid-cols-2'
          : 'lg:grid-cols-2 xl:grid-cols-2',
         'grid grid-cols-2 gap-x-4 gap-y-4 sm:grid-cols-2 sm:gap-x-4 md:grid-cols-2 xl:gap-x-4',
        ]"
        v-if="dataSeriesCurrentMonth">
        <div
         v-for="data in dataSeriesCurrentMonth"
         :key="data"
         @click="
          $route.path.includes('queue-remove-this')
           ? (getQueueDetailsDataObj(new Array(data.name.split(' - ')[0])),
             (showDetail = true),
             (selectedElement = data.name))
           : ''
         ">
         <DonutsDashboard
          :data="data.data"
          :title="data.name"
          :colors="this.colors"
          :name="data.name"
          :fullData="data"
          @loading="isLoading = $event"
          @openDetail="
           getQueueDetailsDataObj($event),
            (showDetail = true),
            (selectedElement = $event)
          " />
        </div>
       </div>
      </div>
     </div>
    </main>
    <TransitionRoot as="template" :show="showDetail">
     <Dialog as="div" class="relative z-20" @close="showDetail = false">
      <div class="fixed inset-0" />

      <div class="fixed inset-0 overflow-hidden">
       <div class="absolute inset-0 overflow-hidden">
        <div
         class="pointer-events-none fixed inset-y-0 right-0 top-12 flex max-w-full pl-10">
         <TransitionChild
          as="template"
          enter="transform transition ease-in-out duration-500 sm:duration-700"
          enter-from="translate-x-full"
          enter-to="translate-x-0"
          leave="transform transition ease-in-out duration-500 sm:duration-700"
          leave-from="translate-x-0"
          leave-to="translate-x-full">
          <DialogPanel class="pointer-events-auto w-screen max-w-3xl">
           <div
            class="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
            <div class="px-4 sm:px-6">
             <div class="flex items-start justify-between">
              <DialogTitle class="text-lg font-medium text-gray-900">{{
               selectedElement
              }}</DialogTitle>
              <div class="ml-3 flex h-7 items-center">
               <button
                type="button"
                class="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                @click="showDetail = false">
                <span class="sr-only">Close panel</span>
                <XIcon class="h-6 w-6" aria-hidden="true" />
               </button>
              </div>
             </div>
            </div>
            <div class="relative mt-4 flex-1">
             <!-- Replace with your content -->
             <div class="absolute border-gray-200 bg-white w-full pb-2">
              <div class="flex flex-col mb-0" v-if="queueData.length > 0">
               <div class="-my-0 overflow-x-auto sm:-mx-6 lg:-mx-0">
                <div
                 class="py-4 align-middle inline-block min-w-full sm:px-4 lg:px-4">
                 <div
                  class="shadow overflow-hidden border-b border-gray-200 rounded-md">
                  <queue-detail-table-origin
                   :tablePaginatedData="queueData"
                   :selectedElement="selectedElement" />
                 </div>
                </div>
               </div>
              </div>
              <div v-else class="pt-20">
               <svg
                xmlns="http://www.w3.org/2000/svg"
                class="mx-auto h-12 w-12 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor">
                <path
                 stroke-linecap="round"
                 stroke-linejoin="round"
                 stroke-width="2"
                 d="M7 12l3-3 3 3 4-4M8 21l4-4 4 4M3 4h18M4 4h16v12a1 1 0 01-1 1H5a1 1 0 01-1-1V4z" />
               </svg>
               <h3 class="mt-2 text-lg font-medium text-gray-900">
                {{ $t("queueHasNoData") }}
               </h3>
              </div>
             </div>
             <!-- /End replace -->
            </div>
           </div>
          </DialogPanel>
         </TransitionChild>
        </div>
       </div>
      </div>
     </Dialog>
    </TransitionRoot>
   </div>
   <div v-show="extensions.length === 0">
    <EmptyStateDashboard :urlType="urlType" />
   </div>
  </template>
 </ExpertStatisticsTemplate>
 <Loading
  v-model:active="isLoading"
  :can-cancel="true"
  :on-cancel="onCancel"
  :is-full-page="fullPage" />
</template>

<script>
import EmptyStateDashboard from "./EmptyStateDashboard.vue";
import ExpertStatisticsTemplate from "../../views/PBX/ExpertStatisticsTemplate.vue";
import DonutsDashboardDuration from "./DonutsDashboardDuration.vue";
import QueueDetailTableOrigin from "./QueueDetailTableOrigin.vue";
import QueueDetailTable from "./QueueDetailTable.vue";
// @ is an alias to /src
import axios from "axios";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import DonutsDashboard from "./DonutsDashboard.vue";
import PbxDashboardSelector from "./PbxDashboardSelector.vue";
import StatsHeading from "./StatsHeading.vue";
import StackedBarChart from "../StackedBarChart.vue";
import TimeSelector from "./TimeSelector.vue";
import BasicColumnDashboard from "./BasicColumnDashboard.vue";
import PbxHeader from "./PbxHeader.vue";
import SuccessBanner from "../../components/SuccessBanner.vue";
import { mapGetters } from "vuex";
import {
 ArrowCircleRightIcon,
 ArrowCircleLeftIcon,
 InformationCircleIcon,
} from "@heroicons/vue/solid";
import {
 Dialog,
 DialogPanel,
 DialogTitle,
 TransitionChild,
 TransitionRoot,
 Switch,
 SwitchGroup,
 SwitchLabel,
 Menu,
 MenuButton,
 MenuItem,
 MenuItems,
} from "@headlessui/vue";
import { XIcon } from "@heroicons/vue/outline";

const pbxMenuType = "statistics";

export default {
 name: "PbxDashboard",
 props: [
  "buttonSelected",
  "dataColumnChartQuarterHourly",
  "dataColumnChartHourly",
  "dataColumnChartQuarterHourlyCons",
  "dataColumnChartHourlyCons",
  "dataLabelsCurrentMonth",
  "dataSeriesCurrentMonth",
  "dataSeriesStacked",
  "dataSeriesConcatenated",
  "extensions",
  "periodSelector",
  "textMonth",
  "timeSelected",
  "urlType",
  "queryElement",
  "dataPbx",
  "dataElementsDuration",
  "dataPbxDay",
  "elementLevelData",
  "elementLevelDataRange",
  "waitingTimeRange",
  "outgoingCallsData",
  "startDate",
  "endDate",
 ],
 components: {
  DonutsDashboard,
  PbxDashboardSelector,
  StatsHeading,
  StackedBarChart,
  TimeSelector,
  BasicColumnDashboard,
  PbxHeader,
  SuccessBanner,
  Loading,
  ArrowCircleRightIcon,
  ArrowCircleLeftIcon,
  QueueDetailTable,
  QueueDetailTableOrigin,
  XIcon,
  Dialog,
  DialogPanel,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
  DonutsDashboardDuration,
  InformationCircleIcon,
  ExpertStatisticsTemplate,
  EmptyStateDashboard,
  Switch,
  SwitchGroup,
  SwitchLabel,
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
 },

 data() {
  return {
   selectedAnsweredType: "hour",
   origins_calls_stats_filter: true,
   origins_calls_stats_default: true,
   answered_calls_stats_day: true,
   showInfoText: true,
   selectedDurationMenuItem: "percentageWaitingTime",
   selectedElement: "",
   queueData: [],
   showDetail: false,
   isLoading: false,
   elementToRemove: "",
   pbxMenuType,
   showChartHourly: false,
   showWaitingDuration: true,
   seriesForColumn: [],
   categoriesForColumn: [],
   elements: [],
   concatenatedChartData: [],
   concatenatedChartDataStacked: [],
   concatenateData: false,
   montlyTotalSeries: [],
   montlyTotalLabels: [],
   totalChartSeries: [],
   totalChartLabels: [],
   pbx3cxData: {},
   callData: [],
   callDataHistory: [],
   callDataParticipants: [],
   callDataSegments: [],
   colors: [
    "#97BAA6",
    "#14233C",
    "#E34B5F",
    "#194E63",
    "#1E7889",
    "#D9B3A3",
    "#EFB0A1",
    "#5B9998",
    "#A5A59D",
    "#CAAB9F",
   ],
   pbxExtensions: [],
   totalExtension: [],
   totalCallQueue: [],
   totalOther: [],
   totalExtensionValue: "",
   pbxQueues: [],
   selectHostname: "",
   stats: [
    {
     name: "Total Extension",
     stat: "1000",
     previousStat: "900",
     change: "10%",
     changeType: "increase",
    },
    {
     name: "Total Callqueue",
     stat: "",
     previousStat: "",
     change: "",
     changeType: "",
    },
    {
     name: "Total Other",
     stat: "",
     previousStat: "",
     change: "",
     changeType: "",
    },
   ],
   successMsg: "",
   activeBanner: false,
   isSuccess: true,
   key: false,
  };
 },
 methods: {
  setElements(event) {
   this.elements = [];
   for (let index = 0; index < event.length; index++) {
    this.elements.push(event[index].label);
   }
  },

  // concatenateChartData(event) {
  //   if (this.dataSeriesCurrentMonth.length) {
  //     let arrayBeforeConcatenate = [];
  //     for (
  //       let index = 0;
  //       index < this.dataSeriesCurrentMonth.length;
  //       index++
  //     ) {
  //       arrayBeforeConcatenate.push(this.dataSeriesCurrentMonth[index].data);
  //     }
  //     this.concatenatedChartData = this.sumArrays(arrayBeforeConcatenate);
  //   }
  // },
  sumArrays(array) {
   if (array.length > 1) {
    let summedArray = [];
    let summedArrayElement0 = array[0][0];
    let summedArrayElement1 = array[0][1];
    let summedArrayElement2 = array[0][2];
    for (let index = 0; index < array.length - 1; index++) {
     summedArrayElement0 = summedArrayElement0 + array[index + 1][0];
     summedArrayElement1 = summedArrayElement1 + array[index + 1][1];
     summedArrayElement2 = summedArrayElement2 + array[index + 1][2];
    }
    summedArray.splice(0, 0, summedArrayElement0);
    summedArray.splice(1, 0, summedArrayElement1);
    summedArray.splice(2, 0, summedArrayElement2);
    return summedArray;
   }
  },
  sortDateTime(data) {
   data.sort(function (a, b) {
    return new Date(a) - new Date(b);
   });
  },
  formatDateToTime(date) {
   if (date) {
    let hours = ("0" + new Date(date).getHours()).slice(-2);
    let minutes = ("0" + new Date(date).getMinutes()).slice(-2);
    let seconds = ("0" + new Date(date).getSeconds()).slice(-2);
    return hours + ":" + minutes + ":" + seconds;
   } else {
    return "null";
   }
  },
  getDataForBasicColumn(data) {
   if (data) {
    let keys = Object.keys(data);
    let keyNames = [];
    keyNames.push(keys[0], keys[1]);
    let obj = {};
    for (let index = 0; index < keyNames.length; index++) {
     const element = keyNames[index];
     obj["name"] = element;
     obj["data"] = data[element];
     this.seriesForColumn.push(obj);
    }
    this.categoriesForColumn = data.labels;
   }
  },

  async getPbx3cxData() {
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/pbx3cx/3/origins`
    );
    this.pbx3cxData = res.data;
    let keys = Object.keys(res.data);
    let extensions = [];
    let queues = [];
    for (let index = 0; index < keys.length; index++) {
     if (keys[index].includes("extension")) {
      let spliceKey = keys[index].split(":");
      extensions.push(spliceKey[1]);
     }
     let spliceQueueKey = keys[index].split(":");
     if (spliceQueueKey[0].includes("queue")) {
      let spliceKey = keys[index].split(":");
      queues.push(spliceKey[1]);
     }
    }
    this.pbxExtensions = extensions.filter(function (item, pos, self) {
     return self.indexOf(item) == pos;
    });
    this.pbxQueues = queues.filter(function (item, pos, self) {
     return self.indexOf(item) == pos;
    });
    return res.data;
   } catch (error) {
    this.errorHandling(error);
   }
  },
  async getMontlyPbxDataForExtensionOrQueue(numb) {
   let data = await this.getPbx3cxData();
   let keys = Object.keys(data);
   keys.forEach((element, index) => {
    let spliceKey = keys[index].split(":");
    if (keys[index].includes("extension") && spliceKey[2].length === 6) {
     this.totalExtension.push(parseInt(data[keys[index]]));
    }
    let spliceQueueKey = keys[index].split(":");
    if (keys[index].includes("queue") && spliceQueueKey[2].length === 6) {
     this.totalCallQueue.push(parseInt(data[keys[index]]));
    }
    let spliceOtherKey = keys[index].split(":");
    if (keys[index].includes("other") && spliceOtherKey[2].length === 6) {
     this.totalOther.push(parseInt(data[keys[index]]));
    }
   });
   this.getTotal();
   // let montlyTotal = undefined;
   for (let index = 0; index < keys.length; index++) {
    let spliceQueueKey = keys[index].split(":");
    if (
     numb === spliceQueueKey[1] &&
     spliceQueueKey[2] === this.currentMonthAndYearForPbx
    ) {
     this.montlyTotal = data[keys[index]];
    }
   }
  },
  getTotal() {
   const add = arr => arr.reduce((a, b) => a + b, 0);
   this.stats[0].stat = add(this.totalExtension);
   this.stats[1].stat = add(this.totalCallQueue);
   this.stats[2].stat = add(this.totalOther);
   for (let index = 0; index < this.stats.length; index++) {
    this.totalChartSeries.push(this.stats[index].stat);
    this.totalChartLabels.push(this.stats[index].name);
   }
   this.stats[0].previousStat = 0;
   this.stats[1].previousStat = 0;
   this.stats[2].previousStat = 0;
   this.stats[0].change = "0%";
   this.stats[1].change = "0%";
   this.stats[2].change = "0%";
  },
  parseDateRange(date) {
   if (date.length === 8) {
    let year = ("0" + date).slice(1, -4);
    let month = ("0" + date).slice(-4, -2);
    let day = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = day + "/" + month + "/" + year;
    return String(fullDay);
   } else if (date.length === 6) {
    let year = ("0" + date).slice(1, 5);
    let month = ("0" + date).slice(-2);
    let fullDay = "";
    fullDay = month + "/" + year;
    return String(fullDay);
   } else if (date.length === 17) {
    let fullDay = date.split("-");
    let firstYear = ("0" + fullDay[0]).slice(1, 5);
    let firstMonth = ("0" + fullDay[0]).slice(-4, -2);
    let firstDay = ("0" + fullDay[0]).slice(-2);

    let firstFullDay = "";
    firstFullDay = firstDay + "/" + firstMonth + "/" + firstYear;

    let lastYear = ("0" + fullDay[1]).slice(1, 5);
    let lastMonth = ("0" + fullDay[1]).slice(-4, -2);
    let lastDay = ("0" + fullDay[1]).slice(-2);

    let lastFullDay = "";
    lastFullDay = lastDay + "/" + lastMonth + "/" + lastYear;
    let finalDay = "";
    finalDay = firstFullDay + " - " + lastFullDay;
    return String(finalDay);
   } else {
    return date;
   }
  },
  parseTimeRange(time) {
   if (time.length > 0) {
    const timeStart = time[0].slice(0, 2) + ":" + time[0].slice(2);
    const timeEnd = time[1].slice(0, 2) + ":" + time[1].slice(2);
    const finalTime = timeStart + " - " + timeEnd;
    return String(finalTime);
   }
  },
  async getQueueDetails(element, sentPeriod) {
   let period = "";
   if (sentPeriod) {
    period = sentPeriod;
   } else {
    period = this.periodSelector;
   }
   try {
    const res = await axios.get(
     `${this.$cookie.getCookie("API")}/api/v1/pbxData/${
      this.hostName
     }/callQueues?&call_queue=${element}&days=${period}`
    );

    return res.data;
   } catch (error) {
    this.errorHandling(error);
    this.isLoading = false;
   }
  },
  async getQueueDetailsDataObj(event) {
   this.isLoading = true;

   let queuesData = {};
   let queueData = {};

   if (this.pbxElements && event && event.length > 0) {
    for (let index = 0; index < event.length; index++) {
     const elementEvent = event[index];

     queueData = {};
     // this.isLoading = true;
     let data = await this.getQueueDetails(elementEvent);

     if (data && Object.keys(data).length > 0) {
      let dataKeys = Object.keys(data);
      let arrayOfElements = this.getAllElementsFromKeys(dataKeys);
      let keysParameters = this.getAllParametersFromKey(dataKeys);
      queueData["data"] = data;
      queueData["dataKeys"] = dataKeys;
      queueData["arrayOfElements"] = arrayOfElements;
      queueData["keysParameters"] = keysParameters;
      queuesData[elementEvent] = queueData;
     }
    }

    if (Object.keys(queuesData).length > 0) {
     if (this.selectedtime && this.selectedtime.length > 1) {
      this.getQueueDetailsDataObjHourly(queuesData);
     } else {
      this.getQueueDetailsDataObjAll(queuesData);
     }
    } else {
     this.isLoading = false;
    }
   } else {
    this.queueData = [];
    this.isLoading = false;
   }
  },
  getQueueDetailsDataObjHourly(data) {
   let queueTime = undefined;
   let dataArr = [];
   let dataObj = {};
   let elementValue = 0;

   let firsQuarterHour =
    (
     "0" +
     (parseInt(this.selectedtime[0].slice(0, 2)) + parseInt(this.hostTimeZone))
    ).slice(-2) + this.selectedtime[0].slice(-2);

   let lastQuarterHour =
    (
     "0" +
     (parseInt(this.selectedtime[1].slice(0, 2)) + parseInt(this.hostTimeZone))
    ).slice(-2) + this.selectedtime[1].slice(-2);

   let keysFromData = Object.keys(data);

   for (let index = 0; index < keysFromData.length; index++) {
    const elementFromData = keysFromData[index];
    let dataForEach = data[elementFromData]["data"];
    let arrayOfElements = data[elementFromData]["arrayOfElements"];
    let dataKeys = data[elementFromData]["dataKeys"];
    let keysParameters = data[elementFromData]["keysParameters"];

    for (let index = 0; index < arrayOfElements.length; index++) {
     dataObj = {};
     const element = arrayOfElements[index];
     if (element === "queue") {
      dataObj["element"] = "Global";
     } else {
      dataObj["element"] = element;
      dataObj["queueName"] = dataForEach["queue:name"];
     }
     for (let index = 0; index < keysParameters.length; index++) {
      elementValue = 0;
      const keysParameter = keysParameters[index];

      dataKeys.forEach((el, index) => {
       let splitEl = el.split(":");

       if (splitEl.length >= 3) {
        let queueElement = splitEl[0];
        let queueDataType = splitEl[1];
        let queuePeriod = splitEl[2] ? splitEl[2] : null;
        let elementName = queueElement + ":" + "name";
        if (queuePeriod) {
         queueTime = queuePeriod.slice(-4);
        }

        if (element == queueElement) {
         dataObj["name"] = dataForEach[elementName];
         if (queueDataType === keysParameter && queueTime) {
          if (
           parseInt(firsQuarterHour) <= parseInt(queueTime) &&
           parseInt(queueTime) <= parseInt(lastQuarterHour)
          ) {
           if (elementValue >= 0) {
            elementValue = elementValue + parseInt(dataForEach[el]);
            dataObj[queueDataType] = elementValue;
           } else {
            dataObj[queueDataType] = 0;
           }
          }
         }
        }
       }
      });
     }
     if (dataObj["element"] == "Global") {
      dataArr.splice(0, 0, dataObj);
     } else {
      dataArr.push(dataObj);
     }
    }
   }

   dataArr = this.reduceGlobalElements(dataArr);

   this.isLoading = false;

   this.queueData = dataArr;
  },
  getQueueDetailsDataObjAll(data) {
   let dataArr = [];
   let dataObj = {};

   let keysFromData = Object.keys(data);

   for (let index = 0; index < keysFromData.length; index++) {
    const elementFromData = keysFromData[index];
    let dataForEach = data[elementFromData]["data"];
    let arrayOfElements = data[elementFromData]["arrayOfElements"];
    let dataKeys = data[elementFromData]["dataKeys"];

    for (let index = 0; index < arrayOfElements.length; index++) {
     dataObj = {};
     const element = arrayOfElements[index];
     if (element === "queue") {
      dataObj["element"] = "Global";
     } else {
      dataObj["queueName"] = dataForEach["queue:name"];
      dataObj["element"] = element;
     }
     dataKeys.forEach((el, index) => {
      let splitEl = el.split(":");
      let queueElement = splitEl[0];
      let queueDataType = splitEl[1];
      let queuePeriod = splitEl[2] ? splitEl[2] : null;
      if (queuePeriod && queuePeriod.length == 12) {
       let queueTime = queuePeriod.slice(-4);
      }
      if (element == queueElement && !queueElement.includes("floc")) {
       if (splitEl.length == 2) {
        dataObj[queueDataType] = dataForEach[el];
       }
      }
     });
     if (dataObj["element"] == "Global") {
      dataArr.splice(0, 0, dataObj);
     } else {
      dataArr.push(dataObj);
     }
    }
   }

   dataArr = this.reduceGlobalElements(dataArr);

   this.isLoading = false;

   this.queueData = dataArr;
  },
  reduceGlobalElements(array) {
   let globalDataArray = [];
   let globalDataKeys = [];
   let globalObj = {};
   let dataArr = array;

   for (let index = 0; index < dataArr.length; index++) {
    const element = dataArr[index];
    if (element.element === "Global") {
     globalDataArray.push(element);
     globalDataKeys.push(Object.keys(element));
    }
   }

   globalDataKeys = globalDataKeys.reduce((r, c) => Object.assign(r, c), {});

   let keysForGlobalKeys = Object.values(globalDataKeys);

   for (let index = 0; index < keysForGlobalKeys.length; index++) {
    const element = keysForGlobalKeys[index];

    globalObj[element] = globalDataArray.reduce(function (accumulator, item) {
     if (parseInt(item[element]) >= 0) {
      return (
       parseInt(accumulator) + (item[element] ? parseInt(item[element]) : 0)
      );
     } else {
      return accumulator + (item[element] ? `, ${item[element]}` : "");
     }
    }, 0);
   }

   dataArr.splice(0, globalDataArray.length, globalObj);

   return dataArr;
  },
  getAllParametersFromKey(keys) {
   let arrayOfElements = [];
   keys.forEach((element, index) => {
    if (!element.includes("floc")) {
     let splitEl = element.split(":");
     arrayOfElements.push(splitEl[1]);
    }
   });
   arrayOfElements = [...new Set(arrayOfElements)];
   return arrayOfElements;
  },
  getAllElementsFromKeys(keys) {
   let elementsArr = [];
   keys.forEach((el, index) => {
    let elSplit = el.split(":");
    let element = elSplit[0];
    if (
     !elementsArr.includes(element) &&
     !element.includes("floc") &&
     element.length > 0
    ) {
     elementsArr.push(element);
    }
   });
   return elementsArr;
  },
 },
 mounted() {
  // this.getPbx3cxData();
  if (!this.pbxElements || this.pbxElements.length == 0) {
   this.isLoading = false;
  }
 },
 computed: {
  ...mapGetters(["pbxElements", "hostName"]),
  currentMonthAndYearForPbx() {
   let month = new Date().getMonth() + 1;
   let year = new Date().getFullYear();
   return String(year) + String(month);
  },
  splitedChartSeries() {
   var i,
    j,
    temporary,
    chunk = 3;
   for (i = 0, j = this.dataSeriesCurrentMonth.length; i < j; i += chunk) {
    temporary = this.dataSeriesCurrentMonth.slice(i, i + chunk);
   }
   return temporary;
  },
 },

 watch: {
  dataSeriesCurrentMonth: function (val) {
   if (val.length) {
    this.getDataForBasicColumn(val[0]);
   }
  },
  showWaitingDuration: function (val) {
   this.$emit("showWaitingDuration", val);
  },
  answered_calls_stats_day: function (val) {
   this.$emit("is_daily", val);
  },
  origins_calls_stats_default: function (val) {
   this.$emit("is_default", val);
  },
  origins_calls_stats_filter: function (val) {
   this.$emit("is_filtered", val);
  },
  selectedAnsweredType: function (val) {
   this.$emit("selected_answered_type", val);
  },
 },
};
</script>

<style>
.aside__max-height {
 height: 100%;
 width: 50%;
 overflow-y: auto;
}
</style>
